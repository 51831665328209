import * as React from "react"

import Layout from "../layouts/index"

// styles
const pageStyles = {
    backgroundImage: "url('/images/Geschäft1.jpg')",
    backgroundSize: 'cover'
}
const headingStyles = {}

// markup
const IndexPage = () => {
    return (
        <Layout>
            <main style={pageStyles}>
                <div className={"container py-4"}>
                    <title>licht-t3 | Impressum</title>
                    <a href={'/'} className={'float-end pb-3'}>Zurück</a>
                    <div className="clearfix"/>
                    <div className="row">
                        <div className="col transparent-bg">
                            <h1 style={headingStyles} className={'display-5'}>
                                Impressum
                            </h1>
                            <p className="fs-4">
                                Uta Freifrau Schenck zu Schweinsberg
                                <br />
                                Uta von Schenck – Lichtkultur
                                <br />
                                Theaterstraße 3
                                <br />
                                37073 Göttingen
                                <br /><br />
                                Telefon: 055149566699
                                <br />
                                Telefax: 055149566691
                                <br />
                                E-Mail: info@uta-von-schenck.de
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage

